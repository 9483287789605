.react-calendar {
  width: 350px;
  max-width: 100%;
  background: white;
  /*border: 1px solid #a0a096;*/
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
  min-width: 350px;
  min-height: 303.22px;
}
.react-calendar--doubleView {
  width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  height: 44px;
  margin-bottom: 1em;
}
.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__navigation button[disabled] {
  background-color: #f0f0f0;
}
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 1em 0.5em 1em 0.5em;
  background: #26ccff;
  margin: 0 0.2em 0 0.2em;
  display: flex;
  justify-content: center;
  border-radius: 5px;
  max-width: 45.81px!important;
  min-width: 45.81px!important;
  min-height: 41.66px!important;
  max-height: 41.66px!important;
}
.react-calendar__month-view__weekNumbers {
  font-weight: bold;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
}
.react-calendar__month-view__days__day--weekend {
  color: #d10000;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}
.react-calendar__tile {
  max-width: 100%;
  text-align: center;
  padding: 0.75em 0.5em;
  background: none;
}
.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__tile--now {
  background: #ffff76;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #ffffa9;
}
.react-calendar__tile--hasActive {
  background: #76baff;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}
.react-calendar__tile--active {
  background: #006edc;
  color: white;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #1087ff;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}

.react-calendar__month-view__weekdays abbr{
  display: flex;
    overflow: hidden;
    white-space: nowrap;
    justify-content: start;
    font-size: 2em!important;
}
.react-calendar__month-view__weekdays .react-calendar__month-view__weekdays__weekday:nth-last-child(1) abbr{
  max-width: 14px!important;
}

.react-calendar__month-view__weekdays .react-calendar__month-view__weekdays__weekday:nth-last-child(2) abbr{
  max-width: 15px!important;
}

.react-calendar__month-view__weekdays .react-calendar__month-view__weekdays__weekday:nth-last-child(3) abbr{
  max-width: 12.9px!important;
}

.react-calendar__month-view__weekdays .react-calendar__month-view__weekdays__weekday:nth-last-child(4) abbr{
  max-width: 16.9px!important;
}

.react-calendar__month-view__weekdays .react-calendar__month-view__weekdays__weekday:nth-last-child(5) abbr{
  max-width: 16.9px!important;
}

.react-calendar__month-view__weekdays .react-calendar__month-view__weekdays__weekday:nth-last-child(6) abbr{
  max-width: 14.3px!important;
}

.react-calendar__month-view__weekdays .react-calendar__month-view__weekdays__weekday:nth-last-child(7) abbr{
  max-width: 14.5px!important;
}

.react-calendar__month-view__days__day {
  padding: 1em 0.5em 1em 0.5em;
  margin: 0.4em 0.1em 0.4em 0.2em!important;
  background: #e7f0f3;
  display: flex;
  justify-content: center;
  border-radius: 5px;
  max-width: 45.81px!important;
  min-width: 45.81px!important;
  min-height: 41.66px!important;
  max-height: 41.66px!important;
}

abbr[title] {
  text-decoration: none!important;
}

.react-calendar__month-view__days__day--neighboringMonth{
  background: #9de2f7;
}

.bg-tasks{
  background: #75A6EF!important;
}
