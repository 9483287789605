.swiper-container {
    padding: 0.3em 1em 1em 1em;
}
#subjectTeacher .swiper-container {
    width: 100%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
  }
#subjectTeacher .swiper-wrapper {
    height: 35em!important;
  }

#subjectTeacher .swiper-slide {
    text-align: center;
    font-size: 18px;
    height: calc((100% - 8em) / 3);
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}

.swiper-button-next{
  top: 15em;
  background: rgba(0, 0, 0, 0.453);
  max-width: 10em;
  width: 3em;
  height: 3em;
  border-radius: 50%;
}

#subjectTeacher .swiper-button-next{
  top: 15em;
  border-radius: 50%;
}

#subjectTeacher .swiper-button-prev{
  top: 15em;
}

.swiper-button-prev{
  top: 15em;
  background: rgba(0, 0, 0, 0.453);
  max-width: 10em;
  width: 3em;
  height: 3em;
  border-radius: 50%;
}

.swiper-button-prev::after{
  color: rgba(255, 255, 255, 0.755);
  font-size: large;
}

.swiper-button-next::after{
  color: rgba(255, 255, 255, 0.755);
  font-size: large;
}